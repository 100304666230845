import { PreferenceLevel } from "../components/library/LegacyPreferenceLevel";
import { TTrackedChangeDiff } from "./APITypes";
import { Maybe } from "./Types";

export enum InclusionRequirement {
  REQUIRED = "Required",
  OPTIONAL = "Optional",
  LEGACY_PROHIBITED = "Prohibited",
}

export enum PlaybookHistoryChangeType {
  // matching the values from the API
  PLAYBOOK_CHANGED = "playbook_changed",
  PLAYBOOK_CREATED = "playbook_created",
  CLAUSE_CHANGED = "clause_changed",
  CLAUSE_DELETED = "clause_deleted",
  CLAUSE_ADDED = "clause_added",
  ALTERNATIVE_LANGUAGE_CHANGED = "alternative_language_changed",
  ALTERNATIVE_LANGUAGE_DELETED = "alternative_language_deleted",
  ALTERNATIVE_LANGUAGE_ADDED = "alternative_language_added",
  CHECK_CHANGED = "check_changed",
  CHECK_DELETED = "check_deleted",
  CHECK_ADDED = "check_added",
}

export enum PlaybookLevelType {
  PLAYBOOK = "playbook",
  CLAUSES = "clauses",
  CHECKS = "checks",
}

export enum UserRole {
  ADMIN = "admin",
  CONTRACT_REVIEWER = "contract_reviewer",
}

export enum CheckScope {
  ALL_CONTRACTS = "all_contracts",
  FIRST_PARTY_PAPER = "first_party_paper",
  THIRD_PARTY_PAPER = "third_party_paper",
}

export enum CheckTestRunState {
  RUNNING = "running",
  COMPLETED = "completed",
}

export enum CheckTestRunResultState {
  pending = "pending",
  generating_suggestion = "generating_suggestion",
  completed = "completed",
  errored = "errored",
}

export enum CheckTestRunResult {
  PASSED = "passed",
  FAILED = "failed",
  LOADING = "loading",
  UNKNOWN = "unknown",
  ERROR = "error",
  NOT_RUN = "not_run",
}

export type TApiErrorResponse = {
  errors: [
    {
      detail: string;
      source?: {
        pointer: string;
      };
    },
  ];
};

export type TClauseTextAlternative = {
  preference_level: PreferenceLevel;
  text: string;
  comment: string;
  alternativeLanguageID: Maybe<string>;
  clauseID: Maybe<string>;
};

export type TSavedClauseTextAlternative = TClauseTextAlternative & {
  clauseID: string;
  alternativeLanguageID: string;
};

export type TClauseTemplate = {
  clauseID: Maybe<string>;
  playbookID: Maybe<string>;
  name: string;
  inclusion_requirement: InclusionRequirement;
  text: string;
  guidance: string;
  guidance_version: number;
  notes: string;
  clause_text_alternatives: TClauseTextAlternative[];
  checks: TClauseTemplateCheck[];
};

export type TSavedClauseTemplate = TClauseTemplate & {
  clauseID: string;
  playbookID: string;
};

export function isSavedClauseTemplate(clause: TClauseTemplate): clause is TSavedClauseTemplate {
  return typeof clause.clauseID === "string" && typeof clause.playbookID === "string";
}

export type TCheck = {
  checkID: Maybe<string>;
  name: string;
  text: string;
  scope: CheckScope;
  notes: string;
  resolutionGuidance: string;
  commentsEnabled: boolean;
  commentGuidance: string;
  createdAt: string;
  updatedAt: string;
  disabledAt: Maybe<string>;
  playbookID?: Maybe<string>;
  clauseTemplateID?: Maybe<string>;
};

export type TManagedCheck = TCheck & {
  playbookID: null | undefined;
};

export type TSavedManagedCheck = TCheck & {
  playbookID: null | undefined;
  checkID: string;
};

export type TSavedCheck = TCheck & {
  checkID: string;
};

export type TPlaybookCheck = TCheck & {
  playbookID: string;
};

export type TClauseTemplateCheck = TPlaybookCheck & {
  clauseTemplateID: string;
};

export type TSavedPlaybookCheck = TSavedCheck & {
  playbookID: string;
};

export type TSavedClauseTemplateCheck = TSavedPlaybookCheck & {
  clauseTemplateID: string;
};

export function isSavedClauseTemplateCheck(check: TCheck): check is TSavedClauseTemplateCheck {
  return (
    typeof check.clauseTemplateID === "string" &&
    typeof check.checkID === "string" &&
    typeof check.playbookID === "string"
  );
}

export type TPlaybookEditHistory = {
  changeType: PlaybookHistoryChangeType;
  clauseName: string;
  clauseTemplateID: string;
  clauseTextAlternativeID: string;
  checkName: string;
  checkID: string;
  user: TUser;
  createdAt: string;
  changedFields: {
    fieldName: string;
    oldValue: string;
    newValue: string;
  }[];
};

export type TManagedCheckSuite = {
  checkSuiteID: Maybe<string>;
  name: string;
  description: string;
  publishedAt?: Maybe<string>;
};

export type TPlaybookMetadata = {
  name: string;
  description: string;
};

export type TPlaybookTestContract = {
  id: string;
  name: string;
  playbookID: string;
  createdAt: string;
  updatedAt: string;
};

export type TUser = {
  id: string;
  microsoftID: string;
  name: string;
  email: string;
  role: Maybe<UserRole>;
  createdAt: string;
  updatedAt: string;
};

export type TInvite = {
  id: string;
  email: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
};

export function isUserWithRole(arg: any): arg is TUser {
  return (
    arg &&
    typeof arg?.id === "string" &&
    typeof arg?.microsoftID === "string" &&
    typeof arg?.name === "string" &&
    typeof arg?.email === "string" &&
    Object.values(UserRole).includes(arg?.role) &&
    typeof arg?.createdAt === "string" &&
    typeof arg?.updatedAt === "string"
  );
}

export type TPlaybook = {
  playbookID: Maybe<string>;
  metadata: TPlaybookMetadata;
  updatedAt: Maybe<string>;
  createdBy: TUser;
  useClauseChecks: boolean;
};

export type TSavedPlaybook = TPlaybook & {
  playbookID: string;
  managedCheckSuites: TSavedManagedCheckSuite[];
};

export type TSavedManagedCheckSuite = TManagedCheckSuite & {
  checkSuiteID: string;
};

export type TExampleSuggestion = {
  exampleID: string;
  checkID: string;
  originalText: string;
  suggestedText: string;
  suggestedComment: string;
  createdAt: string;
  createdBy: TUser;
};

export type TCheckTestRun = {
  id: string;
  state: CheckTestRunState;
  results: TCheckTestRunResult[];
  createdAt: string;
  updatedAt: string;
};

export type TCheckTestRunResult = {
  contract: TPlaybookTestContract;
  checkTestRunID: string;
  state: CheckTestRunResultState;
  result: CheckTestRunResult | null;
  explanation: string | null;
  sources: string[] | null;
  suggestions: Suggestion[] | null;
  errorGeneratingSuggestions: string | null;
  matchedClause: TTrackedChangeDiff[] | null;
};

export type Suggestion = {
  id: string;
  originalText: string;
  suggestedText: string;
  suggestedComment: string;
};

export type TClauseCheckResultBenchmarkExample = {
  checkText: string;
  exampleName: string;
  clauseTemplateID: string;
  documentClauseText: string;
  expectedResult: CheckTestRunResult;
  expectedSources: string[];
};

export type TContractCheckResultBenchmarkExample = {
  testContractID: string;
  checkText: string;
  exampleName: string;
  expectedResult: CheckTestRunResult;
  expectedSources: string[];
};

export type TContractCheckFixBenchmarkExample = {
  testContractID: string;
  checkText: string;
  exampleName: string;
  checkResolutionGuidance: string;
  checkCommentGuidance: string;
  checkFailureReason: string;
  checkSourceClauses: string[];
  expectedSuggestionContent: string[];
  expectedCommentContent: string[];
  expectedSuggestionContentOmissions: string[];
  expectedCommentContentOmissions: string[];
};
